import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import UIkit from "uikit";
import WI_day_0 from "../icons/weather/day/0.png";
import WI_day_1 from "../icons/weather/day/1.png";
import WI_day_2 from "../icons/weather/day/2.png";
import WI_day_3 from "../icons/weather/day/3.png";
import WI_day_45 from "../icons/weather/day/45.png";
import WI_day_48 from "../icons/weather/day/48.png";
import WI_day_51 from "../icons/weather/day/51.png";
import WI_day_53 from "../icons/weather/day/53.png";
import WI_day_56 from "../icons/weather/day/56.png";
import WI_day_57 from "../icons/weather/day/57.png";
import WI_day_61 from "../icons/weather/day/61.png";
import WI_day_63 from "../icons/weather/day/63.png";
import WI_day_65 from "../icons/weather/day/65.png";
import WI_day_66 from "../icons/weather/day/66.png";
import WI_day_67 from "../icons/weather/day/67.png";
import WI_day_71 from "../icons/weather/day/71.png";
import WI_day_73 from "../icons/weather/day/73.png";
import WI_day_75 from "../icons/weather/day/75.png";
import WI_day_77 from "../icons/weather/day/77.png";
import WI_day_80 from "../icons/weather/day/80.png";
import WI_day_81 from "../icons/weather/day/81.png";
import WI_day_82 from "../icons/weather/day/82.png";
import WI_day_85 from "../icons/weather/day/85.png";
import WI_day_86 from "../icons/weather/day/86.png";
import WI_day_95 from "../icons/weather/day/95.png";
import WI_day_96 from "../icons/weather/day/96.png";
import WI_day_99 from "../icons/weather/day/99.png";
import WI_night_0 from "../icons/weather/night/0.png";
import WI_night_1 from "../icons/weather/night/1.png";
import WI_night_2 from "../icons/weather/night/2.png";
import WI_night_3 from "../icons/weather/night/3.png";
import WI_night_45 from "../icons/weather/night/45.png";
import WI_night_48 from "../icons/weather/night/48.png";
import WI_night_51 from "../icons/weather/night/51.png";
import WI_night_53 from "../icons/weather/night/53.png";
import WI_night_56 from "../icons/weather/night/56.png";
import WI_night_57 from "../icons/weather/night/57.png";
import WI_night_61 from "../icons/weather/night/61.png";
import WI_night_63 from "../icons/weather/night/63.png";
import WI_night_65 from "../icons/weather/night/65.png";
import WI_night_66 from "../icons/weather/night/66.png";
import WI_night_67 from "../icons/weather/night/67.png";
import WI_night_71 from "../icons/weather/night/71.png";
import WI_night_73 from "../icons/weather/night/73.png";
import WI_night_75 from "../icons/weather/night/75.png";
import WI_night_77 from "../icons/weather/night/77.png";
import WI_night_80 from "../icons/weather/night/80.png";
import WI_night_81 from "../icons/weather/night/81.png";
import WI_night_82 from "../icons/weather/night/82.png";
import WI_night_85 from "../icons/weather/night/85.png";
import WI_night_86 from "../icons/weather/night/86.png";
import WI_night_95 from "../icons/weather/night/95.png";
import WI_night_96 from "../icons/weather/night/96.png";
import WI_night_99 from "../icons/weather/night/99.png";

export default function Loading() {
    const [tempUnit, setTempUnit] = useState('celsius');
    const [weather, setWeather] = useState({});
    const [date, setDate] = useState(new Date());

    const getIcon = (is_day, code) => {
        if (code === 0 && is_day === 1) return <img src={WI_day_0} alt="Clear" width={30} height={30} />;
        if (code === 0 && is_day === 0)  return <img src={WI_night_0} alt="Clear" width={30} height={30} />;
        if (code === 1 && is_day === 1)  return <img src={WI_day_1} alt="Mostly clear" width={30} height={30} />;
        if (code === 1 && is_day === 0)  return <img src={WI_night_1} alt="Mostly clear" width={30} height={30} />;
        if (code === 2 && is_day === 1)  return <img src={WI_day_2} alt="Cloudy" width={30} height={30} />;
        if (code === 2 && is_day === 0)  return <img src={WI_night_2} alt="Cloudy" width={30} height={30} />;
        if (code === 3 && is_day === 1)  return <img src={WI_day_3} alt="Overcast" width={30} height={30} />;
        if (code === 3 && is_day === 0)  return <img src={WI_night_3} alt="Overcast" width={30} height={30} />;
        if (code === 45 && is_day === 1)  return <img src={WI_day_45} alt="Fog" width={30} height={30} />;
        if (code === 45 && is_day === 0)  return <img src={WI_night_45} alt="Fog" width={30} height={30} />;
        if (code === 48 && is_day === 1)  return <img src={WI_day_48} alt="Rime Fog" width={30} height={30} />;
        if (code === 48 && is_day === 0)  return <img src={WI_night_48} alt="Rime Fog" width={30} height={30} />;
        if (code === 51 && is_day === 1)  return <img src={WI_day_51} alt="Drizzle" width={30} height={30} />;
        if (code === 51 && is_day === 0)  return <img src={WI_night_51} alt="Drizzle" width={30} height={30} />;
        if (code === 53 && is_day === 1)  return <img src={WI_day_53} alt="Mild Drizzle" width={30} height={30} />;
        if (code === 53 && is_day === 0)  return <img src={WI_night_53} alt="Mild Drizzle" width={30} height={30} />;
        if (code === 55 && is_day === 1)  return <img src={WI_day_55} alt="Dense Drizzle" width={30} height={30} />;
        if (code === 55 && is_day === 0)  return <img src={WI_night_55} alt="Dense Drizzle" width={30} height={30} />;
        if (code === 56 && is_day === 1)  return <img src={WI_day_56} alt="Freezing Drizzle" width={30} height={30} />;
        if (code === 56 && is_day === 0)  return <img src={WI_night_56} alt="Freezing Drizzle" width={30} height={30} />;
        if (code === 57 && is_day === 1)  return <img src={WI_day_57} alt="Dense Freezing Drizzle" width={30} height={30} />;
        if (code === 57 && is_day === 0)  return <img src={WI_night_57} alt="Dense Freezing Drizzle" width={30} height={30} />;
        if (code === 61 && is_day === 1)  return <img src={WI_day_61} alt="Slight Rain" width={30} height={30} />;
        if (code === 61 && is_day === 0)  return <img src={WI_night_61} alt="Slight Rain" width={30} height={30} />;
        if (code === 63 && is_day === 1)  return <img src={WI_day_63} alt="Moderate Rain" width={30} height={30} />;
        if (code === 63 && is_day === 0)  return <img src={WI_night_63} alt="Moderate Rain" width={30} height={30} />;
        if (code === 65 && is_day === 1)  return <img src={WI_day_65} alt="Heavy Rain" width={30} height={30} />;
        if (code === 65 && is_day === 0)  return <img src={WI_night_65} alt="Heavy Rain" width={30} height={30} />;
        if (code === 66 && is_day === 1)  return <img src={WI_day_66} alt="Freezing Rain" width={30} height={30} />;
        if (code === 66 && is_day === 0)  return <img src={WI_night_66} alt="Freezing Rain" width={30} height={30} />;
        if (code === 67 && is_day === 1)  return <img src={WI_day_67} alt="Heavy Freezing Rain" width={30} height={30} />;
        if (code === 67 && is_day === 0)  return <img src={WI_night_67} alt="Heavy Freezing Rain" width={30} height={30} />;
        if (code === 71 && is_day === 1)  return <img src={WI_day_71} alt="Slight Snow" width={30} height={30} />;
        if (code === 71 && is_day === 0)  return <img src={WI_night_71} alt="Slight Snow" width={30} height={30} />;
        if (code === 73 && is_day === 1)  return <img src={WI_day_73} alt="Moderate Snow" width={30} height={30} />;
        if (code === 73 && is_day === 0)  return <img src={WI_night_73} alt="Moderate Snow" width={30} height={30} />;
        if (code === 75 && is_day === 1)  return <img src={WI_day_75} alt="Heavy Snow" width={30} height={30} />;
        if (code === 75 && is_day === 0)  return <img src={WI_night_75} alt="Heavy Snow" width={30} height={30} />;
        if (code === 77 && is_day === 1)  return <img src={WI_day_77} alt="Snow Grains" width={30} height={30} />;
        if (code === 77 && is_day === 0)  return <img src={WI_night_77} alt="Snow Grains" width={30} height={30} />;
        if (code === 80 && is_day === 1)  return <img src={WI_day_80} alt="Slight Showers" width={30} height={30} />;
        if (code === 80 && is_day === 0)  return <img src={WI_night_80} alt="Slight Showers" width={30} height={30} />;
        if (code === 81 && is_day === 1)  return <img src={WI_day_81} alt="Moderate Showers" width={30} height={30} />;
        if (code === 81 && is_day === 0)  return <img src={WI_night_81} alt="Moderate Showers" width={30} height={30} />;
        if (code === 82 && is_day === 1)  return <img src={WI_day_82} alt="Violent Showers" width={30} height={30} />;
        if (code === 82 && is_day === 0)  return <img src={WI_night_82} alt="Violent Showers" width={30} height={30} />;
        if (code === 85 && is_day === 1)  return <img src={WI_day_85} alt="Snow showers" width={30} height={30} />;
        if (code === 85 && is_day === 0)  return <img src={WI_night_85} alt="Snow showers" width={30} height={30} />;
        if (code === 86 && is_day === 1)  return <img src={WI_day_86} alt="Heavy Snow showers" width={30} height={30} />;
        if (code === 86 && is_day === 0)  return <img src={WI_night_86} alt="Heavy Snow showers" width={30} height={30} />;
        if (code === 95 && is_day === 1)  return <img src={WI_day_95} alt="Tunderstorm" width={30} height={30} />;
        if (code === 95 && is_day === 0)  return <img src={WI_night_95} alt="Tunderstorm" width={30} height={30} />;
        if (code === 96 && is_day === 1)  return <img src={WI_day_96} alt="Tunderstorm with slight hail" width={30} height={30} />;
        if (code === 96 && is_day === 0)  return <img src={WI_night_96} alt="Tunderstorm with slight hail" width={30} height={30} />;
        if (code === 99 && is_day === 1)  return <img src={WI_day_99} alt="Tunderstorm with heavy hail" width={30} height={30} />;
        if (code === 99 && is_day === 0)  return <img src={WI_night_99} alt="Tunderstorm with heavy hail" width={30} height={30} />;
    }
    
    const getWeather = (temp) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const url = `https://api.open-meteo.com/v1/forecast?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&current_weather=true&temperature_unit=${temp}`;
                    await fetch(url)
                        .then(async (response) => await response.json())
                        .then((weather) => {
                            setTempUnit(temp);
                            setWeather(weather.current_weather);
                            // console.log(weather.current_weather);
                        })
                        .catch((err) => UIkit.notification(`API Error: ${err}`, {status:'danger', pos: 'top-center'}))
                }
            ), (err) => console.log(err)
        }
    }

    useEffect(() => {
        setInterval(() => setDate(new Date()), 20000);

        if (localStorage.getItem('temp_unit') !== null) {
            setTempUnit(localStorage.getItem('temp_unit'));
            getWeather(localStorage.getItem('temp_unit'));
        }
    }, []);
    
    const toggleWeather = () => {
        let unit = "celsius";
        if (tempUnit != "fahrenheit") unit = "fahrenheit";
        localStorage.setItem('temp_unit', unit);
        setTempUnit(unit);
        getWeather(unit);

    }

    return (
        <nav className="uk-navbar-container" data-uk-sticky>
            <div className="uk-container uk-container-expand">
                <div data-uk-navbar>
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            <li>
                                <NavLink className={({ isActive, isPending }) => isPending ? "uk-link" : isActive ? "uk-active" : ""} to="/" aria-label="MyHaus">
                                    <svg className="uk-preserve"  width="53px" height="53px" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#fefefe">
                                        <path d="M9 21H7a4 4 0 01-4-4v-6.292a4 4 0 011.927-3.421l5-3.03a4 4 0 014.146 0l5 3.03A4 4 0 0121 10.707V17a4 4 0 01-4 4h-2m-6 0v-4a3 3 0 013-3v0a3 3 0 013 3v4m-6 0h6" stroke="#fefefe" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="uk-navbar-right">
                        <div className="uk-navbar-item uk-link" onMouseDownCapture={() => toggleWeather()}>
                            <div className="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-circle" style={weather.is_day === 1 ? {color: '#fefe01'} : {color: '#fefefe'}}>
                                {weather.is_day !== undefined ?
                                <>
                                    {getIcon(weather.is_day, weather.weathercode)}
                                    <span>
                                        {weather.temperature}&deg;
                                        {tempUnit == 'fahrenheit' ? 'F' : 'C'}
                                    </span>
                                </> :
                                <>
                                    <span className="uk-link" data-uk-icon="icon:location" onPointerDown={(e) => {if (e.isPrimary) getWeather(tempUnit)}}></span>
                                </>
                                }
                            </div>
                        </div>
                        <p className="uk-navbar-item uk-text-right uk-margin-remove">
                            {' '}
                            {date.toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                            })}
                            <br/>
                            {date.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}
                        </p>
                    </div>
                </div>
            </div>
        </nav>
    );
};