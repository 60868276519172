export default function Loading() {
    return (
        <>
            <div className='uk-container-expand uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-background-primary' data-uk-height-viewport="offset-top: true">
                <h2 className='uk-h1 uk-text-center uk-margin-remove'>Loading...</h2>
                <p className='uk-text-lead uk-text-center uk-margin-remove'>please be patient</p>
                <p className='uk-heading-line uk-text-center uk-width-1-1'><span data-uk-icon="icon:happy"></span></p>
                <div className="uk-margin uk-padding" data-uk-spinner></div>
            </div>
        </>
    );
};