import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Loading from "./components/Loading";
import NavigationBar from "./components/NavigationBar";

if (!localStorage.getItem('uuid')) localStorage.setItem('uuid', uuid());

const Home = React.lazy(() => import("./pages/Home"));
const Games = React.lazy(() => import("./pages/Games"));
const Apps = React.lazy(() => import("./pages/Apps"));
const Notes = React.lazy(() => import("./pages/Notes"));
const Bookmarks = React.lazy(() => import("./pages/Bookmarks"));
const NotFound = React.lazy(() => {
    return new Promise(resolve => {
      setTimeout(() => resolve(import("./pages/404")), 2000);
    });
});

if ('serviceWorker' in navigator) {
    window.addEventListener("load", () => {
    navigator.serviceWorker.register(new URL('./sw.js', import.meta.url))
    .then((registration) => {
        const data = {
            type: 'CACHE_URLS',
            payload: [
                location.href,
                ...performance.getEntriesByType('resource').map((r) => r.name)
            ]
        };
        registration.installing.postMessage(data);
    })
    .catch(err => console.error(`Service Worker Error: ${err}`));
    });
} else {
    console.log("Service Worker is not supported by browser.");
}

const App = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
            if (!navigator.onLine) UIkit.notification("You are offline!", {status:'warning', pos: 'top-center'});
        };
        window.addEventListener('online', handleStatusChange);
        window.addEventListener('offline', handleStatusChange);
        return () => {
        window.removeEventListener('online', handleStatusChange);
        window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    return (
        <Router>
            <NavigationBar />
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" element={<Home isOnline={isOnline}/>} />
                    <Route path="/games" element={<Games isOnline={isOnline}/>} />
                    <Route path="/apps" element={<Apps isOnline={isOnline}/>} />
                    <Route path="/marks" element={<Bookmarks isOnline={isOnline}/>} />
                    <Route path="/notes" element={<Notes />} />
                    <Route path="*" element={<NotFound />} status={404} />
                </Routes>
            </Suspense>
        </Router>
    )
}

UIkit.use(Icons);
createRoot(document.getElementById("app")).render(
    <>
        <App />
    </>
);